import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"

function Wrapper({ children }) {
  const strapi = useStaticQuery(graphql`
    query {
      seo: strapiSeo {
        blogPage {
          pagetitle
          description
          keywords
        }
      }
    }
  `)

  return (
    <Layout
      siteTitle={strapi.seo.blogPage.pagetitle ?? "Blog"}
      description={strapi.seo.blogPage.description ?? ""}
      keywords={strapi.seo.blogPage.keywords ?? ""}
    >
      {children}
    </Layout>
  )
}

export default Wrapper
